import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Post } from "../components/post-component";
import { useAuth } from "../hooks/auth-hook";
import { Paper } from "@mui/material";
import { useParams } from 'react-router-dom';
import {useStoreActions, useStoreState} from "easy-peasy";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import CommunitySummaryCard from "../components/community-summary-card-component";
import {getUserInfo, handleAuthError} from "../services/auth-service";
import {getSortedPostByCommunityId} from "../services/community-service";
import { useCurrentSharePrice } from "../hooks/useCurrentSharePrice";
import FilterBar from "../components/filter-bar-component";
import { SORT_TYPE, SORT_PERIOD } from "../constants";

/**
 * The community main page, which displays a list of Posts
 * @returns {JSX.Element}
 * @constructor
 */

const useCommunityStyles = makeStyles({
  postList: {

    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  postListWrap: {
    maxWidth: "345px",
    marginBottom: "80px",
    width: "100%",
    paddingTop: '80px',
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    " &.MuiPaper-root": {
      backgroundColor: "transparent",
      color: "#ffffff"
    },
  },
});

const Community = (props) => {
  const { getCommunityData } = useAuth();

  const classes = useCommunityStyles();
  const history = useHistory();
  const { communityId } = useParams();
  const [posts, setPosts] = useState([]);
  const [postCount, setPostCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [communityName, setCommunityName] = useState("");
  const [communityMember, setCommunityMember] = useState(0);
  const [filterType, setFilterType] = useState(SORT_TYPE.NEW);
  const [filterPeriod, setFilterPeriod] = useState(SORT_PERIOD.MONTH);

  const walletAddress = useStoreState((state) => state.auth.walletAddress);
  const token = useStoreState((state) => state.auth.token);
  const selectedCommunity = useStoreState((state) => state.settings.currentSelectedCommunity);
  const setSelectedCommunity = useStoreActions((actions) => actions.settings.updateSelectedCommunity);
  const sharePrice = useCurrentSharePrice(selectedCommunity?.walletAddress);

  const clearAuth = useStoreActions(
      (dispatch) => dispatch.auth.clearAuth
  );

  const loadMore = async (page) => {
    const morePosts = await getSortedPostByCommunityId(communityId, filterType, filterPeriod, offset, token, walletAddress);
    if (morePosts.message) {
      handleAuthError(morePosts, clearAuth);
      return
    }
    if (!morePosts || morePosts.length === 0) {
      setHasMore(false);
    }
    setOffset(morePosts.length + offset);
    setPosts([...posts, ...morePosts]);
  };

  const fetchCommunityData = async () => {
    const data = await getCommunityData(communityId, token);
    setCommunityMember(data.memberCount);
    setCommunityName(data.communityName);
    setPostCount(data.postCount);
    setSelectedCommunity(data);
    if (data.message) {
      handleAuthError(data, clearAuth);
    }
  }

  const handleFilterTypeChange = async (filterType) => {
    setFilterType(filterType);
  };

  const handleFilterPeriodChange = async (filterPeriod) => {
    setFilterPeriod(filterPeriod);
  };

  const validateTokenAndCommunityId = async () => {
    try {
      const userInfo = await getUserInfo(token);
      if (!userInfo.communities.includes(communityId)) {
        return false;
      }
      return true;
    } catch (error) {
      clearAuth();
      return false;
    }
  };

  useEffect(() => {

    validateTokenAndCommunityId().then((isValid) => {
      if (isValid) {
        window.scrollTo(0, 0);
        fetchCommunityData();
        loadMore(0);
      } else {
        if (selectedCommunity) {
          history.push(`/c/${selectedCommunity.id}`);
        } else {
          clearAuth();
        }
      }
    });
  }, []);

  useEffect(() => {
    const reloadPost = async (page) => {
      const morePosts = await getSortedPostByCommunityId(communityId, filterType, filterPeriod, 0, token, walletAddress);
      if (morePosts.message) {
        handleAuthError(morePosts, clearAuth);
        return
      }
      if (!morePosts || morePosts.length === 0) {
        setHasMore(false);
        setOffset(0);
        setPosts([])
      } else {
        setHasMore(true);
        setOffset(morePosts.length);
        setPosts([...morePosts]);
      }
    };

    validateTokenAndCommunityId().then((isValid) => {
      if (isValid) {
        reloadPost();
        fetchCommunityData();
      } else {
        history.push(`/c/${selectedCommunity.id}`);
      }
    });
  }, [communityId, filterType, filterPeriod]);

  // todo render a list of post instead of 1 post, use infinite scroller
  // https://codesandbox.io/p/sandbox/infinite-scroll-react-material-ui-zpuj3?file=%2Fsrc%2FImageGrid.js%3A18%2C3-53%2C2
  return (
      <Paper elevation={0} className={classes.postListWrap}>
        <CommunitySummaryCard postNum={postCount || 0} members={communityMember} price={sharePrice} />
        <FilterBar 
          onFilterTypeChange={handleFilterTypeChange}
          onFilterPeriodChange={handleFilterPeriodChange}
        />
        <InfiniteScroll
            pageStart={0}
            dataLength={posts.length}
            hasMore={hasMore}
            next={loadMore}
            sx={{marginBottom: '120px'}}
            loader={<div></div>}
        >
          <div className={classes.postList}>
            {posts.map((post, index) => (
                <Post key={index} {...post} />
            ))}
          </div>
        </InfiniteScroll>
      </Paper>
  );
};

export default withRouter(Community);
