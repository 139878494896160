import CommunitySummaryCard from "../components/community-summary-card-component";
import { getEventsByCommunityId } from "../services/community-service";
import { getUserInfo, handleAuthError } from "../services/auth-service";
import InfiniteScroll from "react-infinite-scroll-component";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from 'react-router-dom';
import { Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { useAuth } from "../hooks/auth-hook";
import { useCurrentSharePrice } from "../hooks/useCurrentSharePrice";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Event } from "../components/event-component";

/**
 * The community main page, which displays a list of Posts
 * @returns {JSX.Element}
 * @constructor
 */

const useCommunityStatStyles = makeStyles({
  eventList: {

    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  eventListWrap: {
    maxWidth: "345px",
    marginBottom: "80px",
    width: "100%",
    paddingTop: '80px',
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    " &.MuiPaper-root": {
      backgroundColor: "transparent",
      color: "#ffffff"
    },
  },
});

const CommunityStats = (props) => {

  const { getCommunityData } = useAuth();
  const classes = useCommunityStatStyles();
  const { communityId } = useParams();
  const [events, setEvents] = useState([]);
  const [eventNum, setEventNum] = useState(0);
  const [postNum, setPostNum] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [communityMember, setCommunityMember] = useState(0);
  const [limit, setLimit] = useState(10);

  const walletAddress = useStoreState((state) => state.auth.walletAddress);
  const token = useStoreState((state) => state.auth.token);
  const history = useHistory();
  const selectedCommunity = useStoreState((state) => state.settings.currentSelectedCommunity);
  const setSelectedCommunity = useStoreActions((actions) => actions.settings.updateSelectedCommunity);
  const sharePrice = useCurrentSharePrice(selectedCommunity?.walletAddress);

  const clearAuth = useStoreActions(
      (dispatch) => dispatch.auth.clearAuth
  );
  
  const loadMore = async (page) => {
    const moreEvents = await getEventsByCommunityId(communityId, token, eventNum, limit);
    if (moreEvents.message) {
      handleAuthError(moreEvents, clearAuth);
      return
    }
    if (!moreEvents || moreEvents.length === 0) {
      setHasMore(false);
    }
    setEventNum(moreEvents.length + eventNum);
    setEvents([...events, ...moreEvents]);
  };

  const fetchCommunityData = async () => {
    const data = await getCommunityData(communityId, token);
    setPostNum(data.postCount);
    setCommunityMember(data.memberCount);
    setSelectedCommunity(data);
    if (data.message) {
      handleAuthError(data, clearAuth);
    }
  }

  const validateTokenAndCommunityId = async () => {
    try {
      const userInfo = await getUserInfo(token);
      if (!userInfo.communities.includes(communityId)) {
        return false;
      }
      return true;
    } catch (error) {
      clearAuth();
      return false;
    }
  };

  useEffect(() => {

    validateTokenAndCommunityId().then((isValid) => {
      if (isValid) {
        window.scrollTo(0, 0);
        fetchCommunityData();
        loadMore(0);
      } else {
        if (selectedCommunity) {
          history.push(`/stats/${selectedCommunity.id}`);
        } else {
          clearAuth();
        }
      }
    });
  }, []);

  useEffect(() => {
    const reloadEvent = async (page) => {
      const moreEvents = await getEventsByCommunityId(communityId, token, 0, limit);
      if (moreEvents.message) {
        handleAuthError(moreEvents, clearAuth);
        return
      }
      if (!moreEvents || moreEvents.length === 0) {
        setHasMore(false);
        setEventNum(0);
        setEvents([])
      } else {
        setHasMore(true);
        setEventNum(moreEvents.length);
        setEvents([...moreEvents]);
      }
    };

    validateTokenAndCommunityId().then((isValid) => {
      if (isValid) {
        reloadEvent();
        fetchCommunityData();
      } else {
        history.push(`/stats/${selectedCommunity.id}`);
      }
    });

  }, [communityId]);

  return (
      <Paper elevation={0} className={classes.eventListWrap}>
        <CommunitySummaryCard postNum={postNum || 0} members={communityMember} price={sharePrice} />
        <InfiniteScroll
            pageStart={0}
            dataLength={events.length}
            hasMore={hasMore}
            next={loadMore}
            sx={{marginBottom: '120px'}}
            loader={<div></div>}
        >
          <div className={classes.eventList}>
            {events.map((event, index) => (
                <Event key={index} {...event} />
            ))}
          </div>
        </InfiniteScroll>
      </Paper>
  );
};

export default withRouter(CommunityStats);
